import React from 'react';
import { Container, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        margin: 'auto',
        width: 400,
        height: 400,
    },
}));

export default function CollectPage() {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth="lg">
                <h1>Waste & Recyclables Collection</h1>
                <Paper className={classes.paper}>
                    <p>Are you a business, in isolation and/or in the vulnerable category for covid-19, and on Mount Nebo, Mount Glorious or surrounding areas?</p>
                    <p>You are currently our highest priority to get collection services established for.</p>
                    <p>Normally we would require the pickup location to rent or purchase specific bins, but with such a need for physical distancing yet still creating waste/recycling we want to help as many as we can.</p>
                    <p>If you are quarantined, practicing physical distancing through isolation or in a vulnerable category, we are happy to arrange no contact (no person to person) removal of rubbish and recyclables on a regular or as needed basis.</p>
                    <p>During this current covid-19 climate the following is in place:<br />
                        All waste must be bagged<br />
                        All recyclables must be in a container that we can easily transfer from to a larger wheelie bin OR able to be taken with us, quarantined for up to 7 days and brought back. (If not using our bins) <br />
                        All recyclables must be clean. We process all recyclables manually (by hand) and we don't need quarter of a jar of off mayonaise contaminating a load of cardboard or worse, ourselves.<br />
                        We do this to help environmental sustainablity and more specifically at the moment to help everyone reduce the risk of exposure to covid-19, the labor is provided free because we want to help, what we can't do is pay for our fuel and sanitising supplies
                        if we don't charge for the service so please don't be shocked when we discuss cost.<br />
                        Rather than posting a set price we will work specific pricing out with each location, trying to reduce cost where possible by aligning multiple pickups at the times.<br />
                    To request information with no obligation please email rrz@cartlin.com letting us know the potential address, required frequency of pickups (daily/weekly/etc), best contact details and any questions you may have and we will try and get back to you within 24 hours.</p>
                </Paper>
            </Container>
        </>
    );
}