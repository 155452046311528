import React from 'react';
import { Container } from "@material-ui/core";

export default function AboutPage() {
    return (
        <>
            <Container maxWidth="lg">
                We want to tell you all about us, what we do, what we want to achieve long term and how we like long walks on the beach, but for now vulnerable people are risking their health when we could be supporting them so this terrible paragraph will have to do instead.
            </Container>
        </>
    );
}