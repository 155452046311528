import React from 'react';
import { Container } from "@material-ui/core";

export default function HelpUsPage() {
    return (
        <>
            <Container maxWidth="lg">
                We would love a great call to arms page showing all the ways people can offer to help and support us, but we needed to get the site live much quicker than we first anticipated so that will just have to wait.
            </Container>
        </>
    );
}