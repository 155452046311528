import React from 'react';
import { Container, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        margin: 'auto',
        width: 400,
        height: 400,
    },
}));

export default function DropPointsPage() {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth="lg">
                <h1>What is recyclable?</h1>
                <Paper className={classes.paper}>
                    <p>Great question, glad you asked. Right now is where we should show you some glossy images and big tabs of recyclable vs non recyclable, but you guessed it that hasn't been written yet so here is the abridged text version.</p>
                    <h4>Recyclable</h4>
                    <p>Metals and metal containing goods, Plastics, Paper/Cardboard, Glass, Electronics</p>
                    <p>All recyclables must be clean. We process all recyclables manually (by hand) and we don't need quarter of a jar of off mayonaise contaminating a load of cardboard or worse, ourselves.</p>
                    <h4>NOT Recyclable</h4>
                    <p>Liquids (some can be by arrangement eg. shop oil), Food/Green Waste (yet, we are working on it), Hazardous materials (eg. we wont recycle your nappies or bars of uranium)</p>
                </Paper>
            </Container>
        </>
    );
}