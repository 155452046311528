import React from 'react';
import { Container } from "@material-ui/core";

export default function LandingPage() {
    return (
        <>
            <Container maxWidth="lg">
                This site wasn't really supposed to be launched yet, but in the midst of the Covid-19 Pandemic, we have decided to speed up rolling out our collection to best support those in isolation to help keep them from needing to do things like head to waste transfer stations.<br />
                If you are on Mount Nebo, Mount Glorious or surrounding areas and could benifit from garbage and/or recycling pickup email rrz@cartlin.com and we will do what we can to help the isolated/vulerable stay safe.
            </Container>
        </>
    );
}