import React from 'react';
import { Container, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
}));

export default function PrivacyPage() {
    const classes = useStyles();

    return (
        <>
        <Container maxWidth="lg">
                <h1>Privacy Policy</h1>
                <Paper className={classes.paper}>
                    <h5>Last updated 31 March 2020</h5>
                    <p>This following document sets forth the Privacy Policy for the Resource Recovery Zone website, http://rrz.com.au.<br />
                        RRZ is committed to providing you with the best possible customer service experience.
                        RRZ is bound by the Privacy Act 1988 (Privacy Act), which sets out a number of principles concerning the privacy of individuals.</p>
                    <h4>Collection of your personal information</h4>
                    There are many aspects of the site which can be viewed without providing personal information, however, for access to some features or services you are required to submit personally identifiable information. 
                    This may include but not limited to a unique username and password, Your name, business name and address, or provide sensitive information in the recovery of your lost password. 
                    Wherever personal information is collected it is done so as a requirement to provide services or features to you.
                    <h4>Sharing of your personal information</h4>
                    We may occasionally hire other companies to provide services on our behalf, including but not limited to handling customer support enquiries, processing transactions or customer freight shipping. 
                    Those companies will be permitted to obtain only the personal information they need to deliver the service. We takes reasonable steps to ensure that these organisations are bound by confidentiality 
                    and privacy obligations in relation to the protection of your personal information.
                    <h4>Use of your personal information</h4>
                    <p>For each visitor to reach the site, we expressively collect the following non-personally identifiable information, including but not limited to IP Address, browser type, version and language, operating system, pages viewed while browsing the Site,
                        page access times and referring website address. This collected information is used solely internally for the purpose of gauging visitor traffic, trends and delivering personalized content to you while you are at this Site.</p>
                    <p>From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice, however, we will make admendments to our privacy policy to keep visitors informed of our updated practices.</p>
                    <h4>Cookies</h4>
                    We may use cookies to store information about visitors preferences, record user-specific information on which pages the user access or visit, customize Web page content based on visitors browser type or other information that the visitor sends via their browser. This information is not of a private nature and cannot be used on a separate machine to bypass account login etc.
                    <h4>Changes to this Privacy Policy</h4>
                    We reserve the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should not access or use the Site.
                    <h4>Accessing Your Personal Information</h4>
                    You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may be required to put your request in writing for security reasons. We reserve the right to charge a fee for searching for, and providing access to, your information on a per request basis.
                    <h4>Contacting us</h4>
                    <p>We welcome your comments regarding this Privacy Policy. If you have any questions about this Privacy Policy and would like further information, please contact us by the following means.</p>
                    <p>E-mail: rrz@cartlin.com</p>
                </Paper>
        </Container>
        </>
    );
}