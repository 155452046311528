import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import NavDrawer from './NavDrawer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        textAlign: "left",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        color: '#EFB000',
    },
}));

export default function TopBar() {

    const classes = useStyles();

    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <div className={classes.root}>
            <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
            <AppBar position="static" className={classes.mainNav}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setDrawerOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <h4 className={classes.title} noWrap>
                        Resource Recovery Zone
          </h4>
                </Toolbar>
            </AppBar>
        </div>
    );
}