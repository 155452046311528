import React from 'react';
import { Container, CardMedia, Paper, makeStyles } from "@material-ui/core";

import dropOffImg from '../static/img/dropoffpoint.jpg';

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        margin: 'auto',
        width: 400,
        height: 400,
    },
}));

export default function DropPointsPage() {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth="lg">
                <h1>Recyclables Drop off Points</h1>
                <CardMedia
                    className={classes.image}
                    component="img"
                    alt="Drop off Container"
                    image={dropOffImg}
                    title="Drop off Container"
                />
                <Paper className={classes.paper}>
                    <p>Currently our only drop off point is 25 Harland Rd, Mount Glorious.</p>
                    <p>This drop off point is available 24 hours from the roadside although their is no lighting so day drop offs are advisable. If you are dropping off large volumes of materials you should let us know in advance so we can ensure the bin is processed as quickly as possible.</p>
                    <p>During the covid-19 measures, please ensure only one car drops off at a time, and also be aware our processing timings are currently much longer to ensure our safety and the safety of further resource processors.</p>
                    <p>If you have or know of potential locations for drop off points to be installed please get in touch.</p>

                </Paper>
            </Container>
        </>
    );
}