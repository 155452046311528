import React from 'react';

import 'typeface-roboto';
import './App.css';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

//import { Provider as StoreProvider } from 'react-redux';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//import store from './reducers';

import TopBar from './components/TopBar';
import Layout from './components/Layout';
import LandingPage from './pages/LandingPage';
import AboutPage from './pages/About';
import ClosedLoopPage from './pages/ClosedLoop';
import CollectPage from './pages/Collect';
import DropPointsPage from './pages/DropPoints';
import HelpUsPage from './pages/HelpUs';
import RecyclablePage from './pages/Recyclable';
import PrivacyPage from './pages/Privacy';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1D120F',
        },
        secondary: {
            main: '#00C4FF',
        }
    },
    typography: {
        fontFamily: 'Roboto',
        color: '#fff',
        h4: {

        },
        h6: {
            fontFamily: 'Roboto',
        }
    },
});

function App() {

    return (
        //<StoreProvider store={store}>
        <MuiThemeProvider theme={theme}>
            <div className="App">
                <Router>
                    <TopBar />
                    <Layout>
                        <Switch>
                            <Route exact path="/">
                                <LandingPage />
                            </Route>
                            <Route exact path="/About">
                                <AboutPage />
                            </Route>
                            <Route exact path="/About/">
                                <AboutPage />
                            </Route>
                            <Route exact path="/ClosedLoop">
                                <ClosedLoopPage />
                            </Route>
                            <Route exact path="/ClosedLoop/">
                                <ClosedLoopPage />
                            </Route>
                            <Route exact path="/Collect">
                                <CollectPage />
                            </Route>
                            <Route exact path="/Collect/">
                                <CollectPage />
                            </Route>
                            <Route exact path="/DropPoints">
                                <DropPointsPage />
                            </Route>
                            <Route exact path="/DropPoints/">
                                <DropPointsPage />
                            </Route>
                            <Route exact path="/HelpUs">
                                <HelpUsPage />
                            </Route>
                            <Route exact path="/HelpUs/">
                                <HelpUsPage />
                            </Route>
                            <Route exact path="/Recyclable">
                                <RecyclablePage />
                            </Route>
                            <Route exact path="/Recyclable/">
                                <RecyclablePage />
                            </Route>
                            <Route exact path="/Privacy">
                                <PrivacyPage />
                            </Route>
                            <Route exact path="/Privacy/">
                                <PrivacyPage />
                            </Route>
                        </Switch>
                    </Layout>
                </Router>
            </div>
        </MuiThemeProvider>
        //</StoreProvider>
    );
}

export default App;