import React from 'react';
import { Container } from "@material-ui/core";

export default function ClosedLoppPage() {
    return (
        <>
            <Container maxWidth="lg">
                The big picture is about so much more than creating a closed loop economy where resources currently made from virgin materials are instead recycled or even better replaced with sustainable alternatives.<br />
                But we can't fill you in on those details yet because one of the resources we haven't been able to recycle yet is time, which we are in short supply of at the moment.
            </Container>
        </>
    );
}