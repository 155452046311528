import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import LoopIcon from '@material-ui/icons/Loop';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoIcon from '@material-ui/icons/Info';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import PolicyIcon from '@material-ui/icons/Policy';


import { withRouter } from 'react-router-dom';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const LIST_OPTIONS = {
    general: [
        {
            label: 'About RRZ',
            icon: InfoIcon,
            link: '/About',
        },
        {
            label: 'Big Picture',
            icon: LoopIcon,
            link: '/ClosedLoop',
        },
        {
            label: 'Privacy Policy',
            icon: PolicyIcon,
            link: '/Privacy',
        },
    ],
    primary: [
        {
            label: 'Waste & Recyclable Collection',
            icon: LocalShippingIcon,
            link: '/Collect',
        },
        {
            label: 'Recyclables Drop off',
            icon: DeleteOutlineIcon,
            link: '/DropPoints',
        },
    ],
    secondary: [
        {
            label: 'What is Recyclable?',
            icon: HelpOutlineIcon,
            link: '/Recyclable',
        },
        {
            label: 'How can I help?',
            icon: NaturePeopleIcon,
            link: '/HelpUs',
        },
    ],
};

const DrawerItem = withRouter(({ history, option }) => (
    <ListItem button key={option.label} onClick={() => history.push(option.link)}>
        <ListItemIcon><option.icon /></ListItemIcon>
        <ListItemText primary={option.label} />
    </ListItem>
));

export default function TemporaryDrawer(props) {
    const classes = useStyles();
    const { open, onClose } = props;

    return (
        <div>
            <Drawer open={open} onClose={onClose}>
                <div
                    className={classes.list}
                    role="presentation"
                    onClick={onClose}
                    onKeyDown={onClose}
                >
                    <List>
                        {LIST_OPTIONS.primary.map((option) => (
                            <DrawerItem option={option} />
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {LIST_OPTIONS.secondary.map((option) => (
                            <DrawerItem option={option} />
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {LIST_OPTIONS.general.map((option) => (
                            <DrawerItem option={option} />
                        ))}
                    </List>
                </div>
            </Drawer>
        </div>
    );
};